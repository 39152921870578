<h2>Abwesenheiten</h2>
<div>
  <nz-divider nzText="Neue Abwesenheit anlegen"></nz-divider>
  <form class="m-t-10" nz-form nzLayout="vertical" [formGroup]="absentForm" *ngIf="absentForm">

      <div nz-row nzGutter="16">
        <div nz-col nzSpan="5">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="type">Grund der Abwesenheit</nz-form-label>
            <nz-form-control>
              <nz-select [disabled]="isLoading" formControlName="typeId" class="w-100">
                <nz-option *ngFor="let type of absentTypes" nzCustomContent [nzLabel]="type?.name"
                           [nzValue]="type.id">
                  {{ type?.name }}
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="5">
          <nz-form-item>
            <nz-form-label nzFor="halfDay">Dauer</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="halfDay">
                <label nz-radio [nzValue]="false">Ganzer Tag</label>
                <label nz-radio [nzValue]="true">Halber Tag</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label nzFor="daterange">Datum / Zeitraum</nz-form-label>
            <nz-form-control>
              <mbsc-datepicker [controls]="['calendar']"
                               [disabled]="isLoading"
                               [select]="absentForm.get('halfDay').value ? 'date' : 'range'"
                               [colors]="color"
                               [labels]="label"
                               [invalid]="rangeSelected"
                               [calendarSize]="1"
                               [showRangeLabels]="true"
                               [inRangeInvalid]="true"
                               [rangeEndInvalid]="false"
                               theme="ios"
                               inputStyle="outline"
                               formControlName="daterange"
                               calendarType="month"

              >


              </mbsc-datepicker>

            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="5">
          <nz-form-item>
            <nz-form-label nzFor="note">Notiz</nz-form-label>
            <nz-form-control>
                    <textarea formControlName="note" [nzAutosize]="{ minRows: 2, maxRows: 6 }" nz-input
                              placeholder="Notiz schreiben (optional)" nzAutosize></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="3">
          <button class="m-t-40"
            nz-button nzType="primary" nzSize="small" (click)="setAbsent()"><i nz-icon
                                                                               nzType="plus"></i>Erstellen
          </button>
        </div>
      </div>



  </form>

</div>

<nz-divider nzText="Liste der Abwesenheiten"></nz-divider>

<ng-container *ngIf="userAbsents?.length <= 0">
  <span><p>Aktuell liegen keine Einträge vor.</p></span>
</ng-container>

<ng-container *ngIf="userAbsents?.length > 0">
  <nz-table nzSize="small" [nzData]="['']">
    <thead>
    <tr>
      <th>Abwesenheit</th>
      <th>Mitarbeiter</th>
      <th>Von / Bis</th>
      <th>Tage</th>
      <th>Notiz</th>
      <th>Erstellt</th>
      <th style="width: 5%;"></th>
      <th style="width: 5%;"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let absent of userAbsents; let i = index">
      <td>
        <nz-tag style="margin-left: 15px"
                [nzColor]="absent.absentType?.color">{{ absent.absentType?.name }}
        </nz-tag>
      </td>
      <td>{{ absent.user?.name }}</td>
      <td>
        {{ absent.start | date:'dd.MM.y' }} <br>
        {{ absent.end | date:'dd.MM.y' }}
      </td>
      <td *ngIf="absent.halfDay">0,5 Tag</td>
      <td *ngIf="!absent.halfDay">{{ calculateDays(absent.start, absent.end) + ' Tage' }}</td>
      <td>{{ absent.note ? absent.note : '-' }}</td>
      <td>{{ absent.createdAt | date:'dd.MM.y H:mm' }}</td>
      <td style="padding-left: 15px;">
        <ng-container *ngIf="absent.approved">
          <nz-tag [nzColor]="'green'">Genehmigt</nz-tag>
        </ng-container>
        <ng-container *ngIf="!absent.approved">
          <button nzSize="small" nz-button nzType="primary" nzShape="circle"
                  (click)="setAbsent(true, absent.id)">
            <i nz-icon nzType="check" nzTheme="outline"></i>
          </button>
        </ng-container>
      </td>
      <td style="padding-left: 15px;">
        <button nzSize="small" nz-button nzType="link" nzDanger nzShape="circle"
                (click)="deleteAbsent(absent.id, i)">
          <i nz-icon nzType="delete"></i></button>
      </td>
    </tr>
    </tbody>
  </nz-table>
</ng-container>

