<h2>Postfächer</h2>
<p>Es werden alle E-Mails der letzten 7 Tage oder "Markierte" E-Mails geladen.</p>

<nz-tabset>
  <nz-tab *ngFor="let inbox of configs" [nzTitle]="inbox.name" (nzClick)="loadImapInbox(inbox)">
    <nz-spin [nzSpinning]="loading">
      <ng-container *ngIf="imapData[inbox.id]">
        <nz-alert
          *ngIf="imapData[inbox.id].error"
          nzType="error"
          nzMessage="Fehler"
          [nzDescription]="imapData[inbox.id].error?.code"
          nzShowIcon
        ></nz-alert>

        <nz-table #inboxTable [nzData]="imapData[inbox.id].emails">
          <thead>
          <tr>
            <th>Betreff</th>
            <th>Von</th>
            <th>Datum</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let message of inboxTable.data">
            <td>{{ message.subject }}</td>
            <td>{{ message.from?.address }}</td>
            <td>{{ message.date | date: 'dd.MM.yyyy HH:mm' }}</td>
            <td>
              <button nz-button nzType="link" (click)="select(inbox.id, message.uid)">Importieren</button>
            </td>
          </tr>
          </tbody>
        </nz-table>
      </ng-container>

    </nz-spin>
  </nz-tab>
</nz-tabset>

