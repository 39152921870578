import {Component, inject, Input, OnInit} from '@angular/core';
import {Counter, CounterReadingInterval, CounterType, Image, Object} from '../../../../../../../database-models';
import {ApiService} from '../../../../services/api.service';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';
import * as uuid from 'uuid';
import moment from 'moment/moment';

export interface ModalData {
  counter: Counter;
}

@Component({
  selector: 'app-edit-counter',
  templateUrl: './edit-counter.component.html',
  styleUrls: ['./edit-counter.component.css']
})
export class EditCounterComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});

  public counter: Counter;

  public counterTypes: CounterType[];
  public objects: Object[];

  public counterForm: FormGroup;
  public title: string = 'Zähler';
  public isLoading = false;
  public schedule = true;

  constructor(
    public api: ApiService,
    private modalService: NzModalService,
    private modal: NzModalRef,
    public formBuilder: FormBuilder,
    private message: NzMessageService,
  ) {
  }

  ngOnInit(): void {
    this.counter ??= this.data.counter;
    this.api.getObjects().subscribe(objects => this.objects = objects);
    this.getAllCounterTypes();
    if (this.counter) {
      this.title = 'Zähler bearbeiten';
    } else {
      this.counter = {
        readingInterval: {
          number: 12,
          unit: 'month'
        } as CounterReadingInterval
      } as Counter;
    }
    this.counter.counterStatuses ??= [];
    this.counter.readingInterval ??= {
      number: 0,
      unit: 'month'
    } as CounterReadingInterval
    this.schedule = this.counter.readingInterval.number > 0;
    this.counter.id = this.counter.id || uuid.v4();
    this.initForm();
  }

  private initForm(): void {
    this.counterForm = this.formBuilder.group(
      {
        name: [this.counter.name],
        note: [this.counter.note],
        counterNumber: [this.counter.counterNumber, [Validators.required]],
        typeId: [this.counter.counterTypeId, [Validators.required]],
        objectId: [this.counter.objectId, [Validators.required]],
        readingIntervalNumber: [this.counter.readingInterval.number],
        readingIntervalUnit: [this.counter.readingInterval.unit],
      },
    );
  }


  public getAllCounterTypes(): void {
    this.isLoading = true;
    this.api.getCounterTypes().subscribe((types: CounterType[]) => {
      this.counterTypes = types;
      this.isLoading = false;
    });
  }

  public setCounter(): void {
    const toSend = this.counterForm.getRawValue();
    this.counter.name = toSend.name;
    this.counter.note = toSend.note;
    this.counter.counterNumber = toSend.counterNumber;
    this.counter.counterTypeId = toSend.typeId;
    this.counter.objectId = toSend.objectId;
    if (this.schedule) {
      this.counter.readingInterval.number = toSend.readingIntervalNumber;
      this.counter.readingInterval.unit = toSend.readingIntervalUnit;
    } else {
      this.counter.readingInterval = null;
    }

    this.api.setCounter(this.counter).subscribe(value => {
      if (this.counter.counterStatuses.length > 0) {
        this.modal.close();
      } else {
        this.modal.close({data: this.counter});
      }
    }, onerror => {

      this.message.warning('Fehler!');

    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public close(): void {
    this.modal.destroy();
  }
}
