export function groupBy(xs: any[], key: string | number): any {
	return xs.reduce(function(rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
}

export function objectJoin(object: any, key: string): string {
	return object.map((item: any): any => item[key]).join(', ')
}

export function getColorByType(type: string): string {
	switch (type) {
		case 'Hausverwaltung':
			return 'cyan';
			break;
		case 'Kunde':
			return 'red';
			break;
		case 'Lieferant':
			return 'blue';
			break;
		default:
			return null;
	}
}
