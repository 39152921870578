<h2>{{title}}</h2>
    <form [formGroup]="counterForm" *ngIf="counterForm">
        <div class="m-b-10" nz-row [nzGutter]="8">
            <nz-form-item nz-col nzSpan="24">
                <nz-form-label nzRequired nzFor="counterNumber">Zählernummer</nz-form-label>
                <nz-form-control>
                    <input formControlName="counterNumber" placeholder="Zählernummer" nz-input>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item nz-col nzSpan="12">
                <nz-form-label nzRequired nzFor="type">Zählertyp</nz-form-label>
                <nz-form-control>
                    <nz-select formControlName="typeId" nzPlaceHolder="wählen Sie den Zählertyp" [disabled]="isLoading" class="w-100">
                        <nz-option *ngFor="let type of counterTypes" nzCustomContent [nzLabel]="type?.name"
                                   [nzValue]="type.id">
                            {{type?.name}}
                            <nz-tag [nzColor]="'orange'">{{type?.unit | titlecase}}</nz-tag>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

          <nz-form-item nz-col nzSpan="12">
            <nz-form-label nzRequired nzFor="objectId">Objekt</nz-form-label>
            <nz-form-control>
              <nz-select formControlName="objectId" nzPlaceHolder="wählen Sie das Objekt" class="w-100">
                <nz-option *ngFor="let object of objects" [nzLabel]="object.name"
                           [nzValue]="object.id">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item nz-col nzSpan="24">
            <label nz-checkbox [(ngModel)]="schedule" (ngModelChange)="counterForm.get('readingIntervalNumber').setValue(1)" [ngModelOptions]="{standalone: true}">Regelmäßige Ablesungen planen {{ schedule ? ' alle:' : '' }}</label>
            <nz-form-control *ngIf="schedule">
              <div nz-row nzGutter="8">

                <nz-input-number nz-col nzSpan="8" class="w-100" [nzMin]="1" [nzMax]="100" formControlName="readingIntervalNumber" ></nz-input-number>
                <nz-select nz-col nzSpan="16" formControlName="readingIntervalUnit" class="w-100">
                  <nz-option [nzLabel]="'Wochen'" [nzValue]="'week'"></nz-option>
                  <nz-option [nzLabel]="'Monate'" [nzValue]="'month'"></nz-option>
                  <nz-option [nzLabel]="'Jahre'" [nzValue]="'year'"></nz-option>

                </nz-select>
              </div>

            </nz-form-control>
          </nz-form-item>


            <nz-form-item nz-col nzSpan="24">
                <nz-form-label nzFor="note">Notiz</nz-form-label>
                <nz-form-control>
                    <textarea formControlName="note" [nzAutosize]="{ minRows: 2, maxRows: 6 }" nz-input
                              placeholder="Notiz schreiben" nzAutosize></textarea>
                </nz-form-control>
            </nz-form-item>
        </div>
    </form>
<button class="m-t-10" nzSize="small" nz-button nzType="link" (click)="close()">Abbrechen</button>

<button [disabled]="counterForm.invalid" class="m-t-10 pull-right" nzSize="small" nz-button
        nzType="primary" (click)="setCounter()">Speichern
</button>



