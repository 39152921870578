<h3>Kontakte verknüpfen</h3>

<nz-tabset>
    <nz-tab [nzTitle]="header">
        <ng-template #header>
            <nz-badge [nzStyle]="{ backgroundColor: '#f58b0a' }"
                      [nzCount]="allContacts?.length"><span>Bestehende Kontakte</span></nz-badge>
        </ng-template>
        <nz-spin [nzSpinning]="isLoading">
          <nz-input-group nz-tooltip nzTooltipTitle="Sie können hier nach Kontaktnamen suchen"
                          [nzPrefix]="prefiXTemplate">
            <input nz-input placeholder="Kontaktname suchen" nzSize="small" [(ngModel)]="nameSearch"
                   (ngModelChange)="filter()">
          </nz-input-group>
          <ng-template #prefiXTemplate>
            <i nz-icon nzType="search" nzTheme="outline"></i>
          </ng-template>
            <app-contact-select-list (setRelation)="setRelation($event)" [mode]="'link'"
                                     [contacts]="allContacts"></app-contact-select-list>
        </nz-spin>
    </nz-tab>
    <nz-tab [nzTitle]="'Neuen Kontakt erstellen'">

        <app-edit-contact-v2 (savedContact)="setRelation($event)"></app-edit-contact-v2>

    </nz-tab>
</nz-tabset>
