import { Injectable } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';
import {EditTaskComponent} from '../components/task/edit-task/edit-task.component';
import {NewTicketComponent, ModalData as NewTicketModalData} from '../../tickets/new-ticket/new-ticket.component';
import {Ticket} from '../../../../../database-models';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private modalService: NzModalService
  ) {
  }


  public newTicketModal(ticket: Ticket = {} as Ticket, mailImport?: any): Promise<Ticket> {
    return new Promise<Ticket>(async (resolve, reject) => {
      const newTicketModal = this.modalService.create<NewTicketComponent, NewTicketModalData>({
        nzContent: NewTicketComponent,
        nzData: {
          ticket,
          mailImport
        },
        nzWidth: '1100px',
        nzFooter: null,
        nzClosable: false,
        nzMaskClosable: false,
        nzOnCancel: instance => {
          reject('Cancel');
        },
        nzOnOk: instance => {
          resolve(instance.ticket);
        }
      });
    })

  }
}
