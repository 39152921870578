import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../../../../database-models';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-username-avatar',
  templateUrl: './username-avatar.component.html',
  styleUrls: ['./username-avatar.component.css']
})
export class UsernameAvatarComponent implements OnInit {

  public backendURL = environment.apiURL;

  @Input()
  public user: User;

  @Input()
  public width: string;

  @Input()
  public imageOnly: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
