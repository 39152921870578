<nz-select (ngModelChange)="selectedUsersChange.emit($event)"
           style="width: 100%"
           [(ngModel)]="selectedUsers"
           nzShowSearch
           [nzAllowClear]="allowClear"
           nzPlaceHolder="- niemandem zugewiesen -"
           [nzSize]="size"
           [nzMaxTagCount]="nzMaxTagCount"
           [compareWith]="compareById"
           [nzDisabled]="readonly"
           [nzMode]="multipleMode ? 'multiple' : 'default'"
>
    <nz-option *ngIf="allowClear" nzLabel="- niemandem zugewiesen -" nzValue=" "></nz-option>
    <nz-option *ngFor="let user of users" [nzLabel]="user.name" [nzValue]="user"></nz-option>
</nz-select>
