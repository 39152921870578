import { Injectable } from '@angular/core';
import {Task} from '../../../../../database-models';
import {NzModalService} from 'ng-zorro-antd/modal';
import {EditTaskComponent, ModalData} from '../components/task/edit-task/edit-task.component';
import {MbscCalendarEvent} from '@mobiscroll/angular';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    private modalService: NzModalService
  ) { }

  public newTaskModal(task: Task = {} as Task): Promise<any> {
    return new Promise<Task | MbscCalendarEvent>(async (resolve, reject) => {
      const modal = this.modalService.create<EditTaskComponent, ModalData>({
        nzContent: EditTaskComponent,
        nzData: {
          task
        },
        nzWidth: '1100px',
        nzFooter: null,
        nzClosable: false,
        nzMaskClosable: false,
        nzOnCancel: instance => {
          reject('Cancel');
        },
        nzOnOk: instance => {
          resolve(instance.task);
        }
      });
    })

  }
}
