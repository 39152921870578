import {Component, inject, Input, OnInit, Optional} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Consumable} from '../../../../../../../database-models';
import {ArticleFull, PagingParameters} from '../../../../../../../Shared/lexoffice-client-js-main/src';

export interface ModalData {
  mode: 'import' | 'select';
}

@Component({
  selector: 'app-lexoffice-articles-list',
  templateUrl: './lexoffice-articles-list.component.html',
  styleUrls: ['./lexoffice-articles-list.component.css']
})
export class LexofficeArticlesListComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: false});
  @Input()
  public mode: 'import' | 'select';

  public loading: boolean = false;
  public lexItems: ArticleFull[];
  public lexResult: any & Partial<PagingParameters>;

  public alreadyImportedIds: string[] = [];

  public pagingParameters: PagingParameters = {
    page: 0,
    size: 25,
  };

  public search: string = '';

  constructor(
    private api: ApiService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    @Optional() private modal: NzModalRef
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.mode ??= this.data.mode;
  }

  public getItems(params?: NzTableQueryParams): void {
    this.loading = true;


    let pagingParameters = this.pagingParameters;
    if (params) {
      const {pageSize, pageIndex, sort, filter} = params;
      pagingParameters.page = pageIndex - 1;
    }

    if (this.search?.length > 3) {
      let filters: any = {
        articleNumber: this.search,
      };

      pagingParameters = {...filters, ...pagingParameters};
    }

    this.api.getLexofficeArticles(pagingParameters).subscribe((result) => {

      this.alreadyImportedIds = result.alreadyImportedIds;
      console.log(result);
      this.lexItems = result.content;
      this.lexResult = result;
      this.loading = false;

    }, error => {
      this.notification.create(
        'error',
        'Fehler',
        error.error
      );
      this.loading = false;
    });
  }

  timeout = null;

  public triggerSearch(skipTimeout: boolean = false): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (this.search?.length >= 3 || this.search?.length <= 0) {
        this.pagingParameters.page = 0;
        this.getItems();
      }

    }, skipTimeout ? 0 : 1000);
  }

  public select(selected: ArticleFull): void {
    switch (this.mode) {
      case 'import':
        this.editItem(selected);
        break;
      case 'select':
        this.modal.close(selected);
        break;
    }
  }

  public editItem(item: ArticleFull): void {

    let newItem: Consumable = {
      name: item.title,
      group: item.type === 'SERVICE' ? 'SERVICE' : 'CONSUMABLE',
      description: item.description,
      unit: item.unitName,
      lexofficeObject: item,
      lexofficeId: item.id
    } as Consumable;

    this.api.createConsumable(newItem).subscribe((consumable: Consumable) => {
      this.notification.create(
        'success',
        'Artikel wurde importiert',
        'Der Artikel wurde erfolgreich importiert.'
      );

      this.getItems();

    }, error => {
      this.notification.create(
        'error',
        'Speichern fehlgeschlagen!',
        error.error
      );
      this.modal.close();
    });
  }


}
