import {CalendarConfig} from './Administration/src/app/shared/interfaces/calendar-config';
// @ts-ignore
import {MbscRecurrenceRule} from '@mobiscroll/angular';

interface DatabaseBaseModels {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
}


export interface Company extends DatabaseBaseModels {
    name: string;
    code: string;
    active: boolean;
    note: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    phone: string;
    fax: string;
    email: string;
    webaddress: string;
    otherinformations: string;
    appwelcometext: string;
    abonnement_id: string;
    abonnement: Abonnment;
    privateTicketsAllowed: boolean;
    privateTicketsDefault: boolean;
    sendMailOnAssignTicket: boolean;
    allowAppUserComments: boolean;
    user_object_permissions: boolean;
    modules: string[];
    logoUrl: string;
    objects?: Object[];
    lex_id: string;
    lex_invoices?: LexInvoice[];
    tasks?: Task[];
    application?: Application;
    sepa_mandat?: SepaMandat;
    billing?: CompanyBilling;
    lexoffice?: CompanyLexoffice;
    storageSize?: number;
    storageSizeTime?: string;
    storageExists?: boolean;
    users?: User[];
    calendarConfig: CalendarConfig;
    bundeslandId?: string;
    bundesland?: Bundesleander;
    lat?: number;
    long?: number;
    mailImportConfigs?: MailImportConfig[];
}

export interface MailImportConfig extends DatabaseBaseModels {
    name: string;
    host: string;
    port: number;
    tls: boolean;
    user: string;
    password: string;
    active: boolean;
    lastResponse: string;
    company_id: string;
    company?: Company;
    _error?: boolean;
}

export interface Bundesleander extends DatabaseBaseModels {
    id: string;
    name: string;
}

export interface CompanyBilling {
    company_id: string;
    accountbalance: number;
    credit: string;
    billingActive: boolean;
    billingActiveFrom: string;
    testPeriodEnd: string;
    email: string;
    terminated: string;
    createdAt?: string;
    updatedAt?: string;
    notes?: string;
    timetrackingmodule: boolean;
    appversion: number; // OLD
    price_id: string;
    price?: Price;
    rating?: number;
    vatId: string;
}

export interface CompanyLexoffice {
    company_id: string;
    active: boolean;
    refresh_token: string;
    refresh_token_created: string;
    refresh_token_expiry: string;
    access_token: string;
    access_token_expiry: string;
    api_key: string;
    organizationId: string;
    connectionId: string;
    taxType: 'net' | 'gross' | 'vatfree';
    smallBusiness: boolean;
    profile: any;
}

export interface BillingTransactions extends DatabaseBaseModels {
    amount: number;
    amount_brutto: number;
    mwst_percent: number;
    reference: string;
    description: string;
    accountbalanceBefore: number;
    accountbalanceAfter: number;
    company_id: string;
    company?: Company;
    lex_invoice_id?: string;
    lex_invoice: LexInvoice;
}

export interface SuperAdmin extends DatabaseBaseModels {
    name: string;
    email: string;
    api_key: string;
    password: string;
    isActive: boolean;
}

export interface User extends DatabaseBaseModels {
    user_type: UserTypes;
    name: string;
    email: string;
    username: string;
    phone: string;
    isAdmin: boolean;
    isActive: boolean;
    multisession: boolean;
    isManager: boolean;
    default_ticket_contributor: boolean;
    emailNotification: boolean;
    company_id: string;
    password: string;
    property_management_id: string;
    teams?: Team[];
    Appuser_Users_Objects?: Appuser_Users_Objects;
    objects?: Object[];
    activePaymentUntil: string;
    nextRenew: string;
    tasks?: Task[];
    company?: Company;
    property_management?: PropertyManagement;
    renew_interval_id?: string;
    renewinterval?: RenewIntervals;
    managerObjects?: Object[];
    employeeObjects?: Object[];
    jwt?: string;
    avatarPath?: string;
    timetracking_timestamps?: TimeTrackingTimeStamps[];
    User_Objects?: Object[];
    color: string;
    absents?: Absent[];
    timeTrackingWorkWeek?: TimeTrackingWorkWeek;
    timeTrackingBegin?: string;
    prescribeBreakTime: boolean;
    member?: 'User';
    Contributors_Users_Tickets: Contributors_Users_Tickets;
    hmac: string;
}

export interface Session extends DatabaseBaseModels {
    api_key: string;
    ip: string;
    device: string;
    userId: string;
    user?: User;
    type: SessionType;
    oneSignalPlayerID: string;
}

export interface Appuser_Users_Objects extends DatabaseBaseModels {
    appuser_type: AppUserTypes;
    house_number: string;
    note: string;
    blocked: boolean;
    objectId: string;
    userId: string;
    user?: User;
}

export interface Appuser extends DatabaseBaseModels {
    Appuser_Users_Objects: Appuser_Users_Objects;
    name: string;
    phone: string;
    customer_type: string;
    house_number: string;
    object_id: string;
    api_key: string;
    uuid: string;
    company_id: string;
}

export interface Object extends DatabaseBaseModels {
    key: string;
    name: string;
    note: string;
    address_line1: string;
    address_line2: string;
    address_line3: string;
    country: string;
    state: string;
    zip: string;
    city: string;
    informations: string;
    company_id: string;
    hausmeisterleistungen: boolean;
    gartenpflege: boolean;
    winterdienst: boolean;
    putzdienst: boolean;
    tiefgaragen: number;
    hausverwaltung_id: string;
    hausverwaltung?: Hausverwaltung;
    hausverwaltungsansprechpartner_id: string;
    hausverwaltungsansprechpartner?: Hausverwaltungsansprechpartner;
    team_id: string;
    team?: Team;
    employee_id?: string;
    employee: User;
    manager_id?: string;
    manager: User;
    objectgroup_id?: string;
    objectgroup?: ObjectGroup;
    news?: News[];
    company?: Company;
    appuser?: Appuser_Users_Objects;
    Appuser_Users_Objects?: Appuser_Users_Objects;
    appusers?: Appuser[];
    einheiten: number;
    heizungsanlage: boolean;
    aufzug: boolean;
    enthaertungsanlage: boolean;
    tg_nassreinigung: boolean;
    aussenwasserhaehne: boolean;
    muelltonnen_bereitstellung: boolean;
    rasen: boolean;
    hecke: boolean;
    wasserfilter_spuelen: boolean;
    wasserfilter_wechseln: boolean;
    hebeanlage: boolean;
    spielplatzkontrolle: boolean;
    ew_kasten: boolean;
    schluessel_muelltonnen: boolean;
    technikraum_zu: boolean;
    namensschild_bestellung: boolean;
    namensschild_art: string;
    namensschild_groesse: string;
    schluessel_anzahl: number;
    biotueten_verteilung: boolean;
    contacts?: Contact[];
    freetextnotes?: FreeTextNote[];
    services?: Service[];
    details?: Detail[];
    objectnotes?: ObjectNote[];
    tasks?: Task[];
    checklists?: CheckList[];
    consumables?: Consumable[];
    Consumable_Tickets?: Consumable_Ticket[];
    property_management_id: string;
    property_management: PropertyManagement;
    property_management_requests?: PropertyManagementRequest[];
    member?: 'Object';
    house_number_required: boolean;
    show_rooms_appuser: boolean;
    objectNumber: string;
    rooms?: Room[];
    service_catalogs: ServiceCatalog[];
    _selected?: boolean;
    archived: boolean;
    counters: Counter[];
    lat: number;
    long: number;
    tags?: Tag[];
}


export interface Ticket extends DatabaseBaseModels {
    object_id?: string;
    customer_id?: string;
    company_id: string;
    name: string;
    description: string;
    hidden_appuser: boolean;
    appuser_id: string;
    state_id: string;
    creator_id?: string;
    images?: Image[];
    state?: State;
    priority?: Priority;
    priority_id: string;
    ticketnotes?: TicketNote[];
    appuser?: Appuser_Users_Objects;
    object?: Object;
    customer?: Customer;
    contact?: Contact;
    contact_id?: string;
    creator?: User;
    private: boolean;
    assignToHV: boolean;
    doUntil: string;
    doUntilAllDay: boolean;
    done: boolean;
    billed: boolean;
    start: string;
    end: string;
    ticketNumber: string;
    contributors?: User[];
    done_by_user_id?: string;
    done_by_user?: User;
    consumables?: Consumable[];
    tasks?: Task[];
    property_management_id?: string;
    property_management?: PropertyManagement;
    sharedtickets?: SharedTicket[];
    files: File[];
    room_id?: string;
    room?: Room;
    worklogs?: WorkLog[];
    localFiles?: LocalFile[];
    mailImport?: any;
    _isCached?: boolean;
    worklogneeded: boolean;
    tags?: Tag[];
    managers?: User[];
    employees?: User[];
    company?: Company;
}

export interface Task extends DatabaseBaseModels {
    title: string;
    description: string;
    color: string;
    done: boolean;
    start: string;
    end: string;
    timezone: 'Europe/Berlin' | string;
    allDay: boolean;
    recurring: MbscRecurrenceRule;
    recurringException: any;
    recurringDone: string[];
    company_id: string;
    ticket_id: string;
    object_id: string;
    customer_id: string;
    user_id: string;
    team_id: string;
    ticket?: Ticket;
    object?: Object;
    customer?: Customer;
    contact?: Contact;
    contact_id?: string;
    user?: User;
    team?: Team;
    company?: Company;
    resource?: string | string[] | User | Team | any;
    backlog: boolean;
    room?: Room;
    room_id: string;
    selectedServices?: selectedServices[] | any;
    import_id: string;
    reference: Object | Contact;
    member?: 'Task';
    wasteplan?: Wasteplan;
    nextOccurrence?: string;
    worklogs?: WorkLog[];
    worklogneeded: boolean;
    tags?: Tag[];
    counters?: Counter[];
    counter_id?: string;
}

export interface Wasteplan extends DatabaseBaseModels {
    title: string;
    done: boolean;
    time: string;
    inTime: string;
    inChecked: boolean;
    outTime: string;
    outChecked: boolean;
    color: string;
    colour: string;
    timezone: 'Europe/Berlin' | string;
    description: string;
    allDay: boolean;
    in_user?: User;
    in_user_id: string;
    out_user?: User;
    out_user_id: string;
    task_out_id?: string;
    task_out?: Task;
    task_in_id?: string;
    task_in?: Task;
    object?: Object;
    object_id: string;
    company?: Company;
    company_id: string;
    _multiSelectTypeOut: string;
    _multiSelectTypeIn: string;
    _outTime: any;
    _inTime: any;
    member?: 'Wasteplan';
}

export interface State extends DatabaseBaseModels {
    name: string;
    background_color: string;
    color: string;
    hidden_appuser: boolean;
    default: boolean;
    isClosed: boolean;
    tickets?: Ticket[];
    orderNumber: number;
    workflowOrderNumber?: number;
    workflowActionName?: string;
    _isCached?: boolean;
}

export interface Priority extends DatabaseBaseModels {
    name: string;
    orderNumber: number;
    background_color: string;
    color: string;
    default: boolean;
    isClosed: boolean;
}

export interface Counter extends DatabaseBaseModels {
    id: string;
    name: string;
    note: string;
    counterNumber: string;
    readingInterval: any;
    nextReadingDate: string;
    markToDo: boolean;
    objectId: string;
    object: Object;
    counterTypeId: string;
    counterType?: CounterType;
    counterStatuses: CounterStatus[];
    localFiles?: LocalFile[];
    files?: File[];
    tasks?: Task[];
    Counter_Task?: Counter_Task;
    _draft?: boolean;
}

export interface Counter_Task {
    done: boolean;
}

export interface CounterReadingInterval {
    number: number;
    unit: 'day' | 'week' | 'month' | 'year';
}

export interface CounterStatus extends DatabaseBaseModels {
    count: number;
    note: string;
    readingDate: string;
    counterId: string;
    userId: string;
    user: User;
    counter: Counter;
    localFiles?: LocalFile[];
    files?: File[];

}

export interface CounterType extends DatabaseBaseModels {
    name: string;
    unit: string;
}

export interface StatesOverviewTickets extends State {
    tickets: Ticket[];
}

export interface TicketOverviewAppuser {
    states: StatesOverviewTickets[];
}


export interface ObjectNote extends DatabaseBaseModels {
    _showImages?: boolean;
    _draft?: boolean;
    titel: string;
    text: string;
    object_id: string;
    room_id: string;
    object?: Object;
    user_id: string;
    employee_id: string;
    user: User;
    managerOnly: boolean;
    localFiles?: LocalFile[];
    files?: File[];
    images?: Image[] | any;
}

export interface TicketNote extends DatabaseBaseModels {
    text: string;
    ticket_id: string;
    user_id: string;
    employee_id: string;
    user: User;
    appuser: Appuser;
    internal: boolean;
}


export interface Image extends DatabaseBaseModels {
    path: string;
    ticket_id: string;
    user_id: string;
    user?: User;
}

export interface News extends DatabaseBaseModels {
    title: string;
    text: string;
    archived: boolean;
    _showDescription: boolean;
    object_id: string;
    objects?: Object[];
}

export interface GlobalNews extends DatabaseBaseModels {
    title: string;
    text: string;
    archived: boolean;
    showForCompanies: boolean;
    showForAppusers: boolean;
    level: 1 | 2 | 3;
    type: AlertType;
    object_id: string;
    company_id: string;
}

export interface Abonnment extends DatabaseBaseModels {
    name: string;
    price: number;
    maxNumberObjects: number;
    maxNumberManager: number;
    intervalDays: number;
}

export interface WorkLog extends DatabaseBaseModels {
    start: string;
    end: string;
    sum: number;
    object_id?: string;
    object?: Object;
    ticket_id?: string;
    ticket?: Ticket;
    task_id?: string;
    task?: Task;
    description: string;
    internalNote: string;
    employee_id: string;
    creator: User;
    involved_users: User[];
    billed: boolean;
    geolocations?: GeoLocation[];
    checkLists?: CheckList[];
    consumed?: Consumable[];
    signatures: Signature[];
    completedServices: completedServices[] | any;
    files?: File[] | any;
    localFiles?: LocalFile[];
    finalized: string;
    customer_id?: string;
    customer?: Customer;
    contact?: Contact;
    contact_id?: string;
    room?: Room;
    room_id?: string;
    _draft?: boolean;
    HV: boolean;
    Appuser_Users_Objects?: Appuser_Users_Objects[];
    _selectedOptionModules: string[];
    company?: Company;
    lexofficeInvoices: any[];
    tags?: Tag[];
}

export interface LocalFile {
    name: string,
    description?: string,
    size?: number,
    uri: string
}

export interface Signature {
    name: string;
    createdAt: string;
    canvas: any;
}

export interface GeoLocation extends DatabaseBaseModels {
    latitude: number;
    longitude: number;
    note: string;
    user_id: string;
    user: User;
    company_id?: string;
    company?: Company;
    worklog_id?: string;
    worklog?: WorkLog;
    timetrackingtimestamp_id?: string;
    timetracking_timestamp?: TimeTrackingTimeStamps;
}

export interface Hausverwaltung extends DatabaseBaseModels {
    name: string;
    email: string;
    phone: string;
    fax: string;
    mobile: string;
    company_id: string;
    key: string;
    address: string;
    zip: string;
    city: string;
    country: string;
}

export interface Hausverwaltungsansprechpartner extends DatabaseBaseModels {
    name: string;
    email: string;
    phone: string;
    fax: string;
    mobile: string;
    hausverwaltung_id: string;
}

export interface Team extends DatabaseBaseModels {
    name: string;
    information: string;
    company_id: string;
    users?: User[];
    member?: 'Team';
}

export interface ObjectGroup extends DatabaseBaseModels {
    name: string;
    objects?: Object[];
}

export interface Contact extends DatabaseBaseModels {
    role: 'customer' | 'vendor' | 'other';
    name: string;
    type: string;
    phone: string;
    mobile: string;
    email: string;
    fax: string;
    city: string;
    zip: string;
    address: string;
    object_id: string;
    emergency: string;
    v2: boolean;
    _showEmail: boolean;
    _showPhone: boolean;
    _showAdresse: boolean;
    salutation?: Salutation;
    Object_Contacts: Contact_Object[];
    Customer_Contact: Customer_Contact[];
    contact_phones?: Phone[];
    contact_addresses?: Address[];
    contact_emails?: Email[];
    contact_contactPeople?: ContactPerson[];
    objects?: Object[];
    lexofficeId?: string;
    member?: 'Contact';
    _selected?: boolean;
}

export interface Address extends DatabaseBaseModels {
    type: string;
    city: string;
    zip: string;
    address: string;
    supplement?: string;
    countryCode?: string;
}

export interface Phone extends DatabaseBaseModels {
    type: string;
    phonenumber: string;
}

export interface Email extends DatabaseBaseModels {
    type: string;
    email: string;
}

export interface ContactPerson extends DatabaseBaseModels {
    firstname: string;
    lastname: string;
    contactId: string;
    salutation?: Salutation;
    contact_phones?: Phone[];
    contact_emails?: Email[];
}

export interface ContactTypes extends DatabaseBaseModels {
    name: string;
}

export interface Contact_Object extends DatabaseBaseModels {
    objectId: string;
    contactId: string;
    objects?: Object[];
    contact?: Contact;
}

export interface Customer_Contact extends DatabaseBaseModels {
    customerId: string;
    contactId: string;
    cusotmer?: Customer;
    contact?: Contact;
}

export interface Object_Customer extends DatabaseBaseModels {
    objectId: string;
    customerId: string;
    objects?: Object[];
    customer?: Contact;
}

export interface Consumable extends DatabaseBaseModels {
    name: string;
    description: string;
    unit: string;
    group: string;
    price: number;
    company_id: string;
    blocked: boolean;
    Consumable_Ticket?: Consumable_Ticket;
    lexofficeId: string;
    lexofficeObject: any;
    _hidden: boolean;
}

export interface Consumable_Ticket extends DatabaseBaseModels {
    consumableId: string;
    ticketId?: string;
    objectId?: string;
    companyId?: string;
    number: number;
    consumable?: Consumable;
    object?: Object;
    company?: Company;
    ticket?: Ticket;
    worklogId: string;
    worklog?: WorkLog;
    _isTouched?: boolean;
}

export interface Customer extends DatabaseBaseModels {
    id: string;
    companyname?: string;
    firstname: string;
    lastname: string;
    type: CustomerType;
    phone: string;
    mobile: string;
    email: string;
    fax: string;
    city: string;
    zip: string;
    address: string;
    object_id: string;
    salutation: Salutation;
    member?: 'Customer';
    tickets?: Ticket[];
    tasks?: Task[];
    _selected?: boolean;
    Object_Customer: Object_Customer[];
    Customer_Contacts: Customer_Contact[];
    contacts: Contact[];
}

export interface File extends DatabaseBaseModels {
    name: string;
    description: string;
    type: any;
    url: string;
    size: any;
    user_id: string;
    user?: User;
    ticket_id: string;
    ticket?: Ticket;
    object_id: string;
    object?: Object;
    company_id: string;
    company?: Company;
    counter_status_id?: string;
    counterStatus?: CounterStatus;
    counter_id?: string;
    counter?: Counter;
    worklog_id: string;
    worklog: WorkLog;
    upload?: boolean;
    onlyManager?: boolean;
    _showFullDescription?: boolean;
    _base64image: string;
}

export interface Application extends DatabaseBaseModels {
    company_name: string;
    firstname: string;
    lastname: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    email: string;
    phone: string;
    employees: number;
    objects: number;
    message: number;
    done: boolean;
    emailApproved: boolean;
    company_id: string;
    property_management_id: string;
    company?: Company;
    property_management?: PropertyManagement;
    type: ApplicationTypes;
    source: string;
}

export interface FreeTextNote extends DatabaseBaseModels {
    title: string;
    text: string;
    object_id: string;
    object?: Object;
}

export interface Invoice extends DatabaseBaseModels {
    nr: number;
    recipient: string;
    vat: number;
    total_vat: number;
    total_netto: number;
    total_brutto: number;
    paid_date: string;
    storno_date: string;
    file_url: string;
    invoicepositions: InvoicePosition[];
    company_id: string;
    company?: Company;
}

export interface InvoicePosition extends DatabaseBaseModels {
    position: number;
    description: string;
    quantity: number;
    single_netto: number;
    total_netto: number;
    invoice_id: string;
    invoice?: Invoice;
}

export interface Service extends DatabaseBaseModels {
    id: string;
    name: string;
    object_id: string;
    company_id: string;
}

export interface PropertyManagement extends DatabaseBaseModels {
    id: string;
    name: string;
    active: boolean;
    note: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    phone: string;
    fax: string;
    email: string;
    webaddress: string;
    otherinformations: string;
    logoURL: string;
    verified: boolean;
    application_id?: string;
    application?: Application;
}

export interface PropertyManagementRequest extends DatabaseBaseModels {
    id: string;
    accepted: boolean;
    done: boolean;
    object_id: string;
    object?: Object;
    property_management_id: string;
    property_management?: PropertyManagement;
}

export interface ServiceTemplate extends DatabaseBaseModels {
    id: string;
    name: string;
}

export interface Detail extends DatabaseBaseModels {
    id: string;
    name: string;
    object_id: string;
    company_id: string;
}

export interface DetailTemplate extends DatabaseBaseModels {
    id: string;
    name: string;
}

export interface Notification extends DatabaseBaseModels {
    title: string;
    text: string;
    type: string;
    read: boolean;
    actionURL: string;
    notification_priority_id: string;
    priority: NotificationPriority;
    sendTo: string;
    ticket_id: string;
    ticket?: Ticket;
}

export interface NotificationPriority extends DatabaseBaseModels {
    name: string;
    short: string;
    muteable: boolean;
    deletable: boolean;
    notifications?: Notification[];
}

export interface MuteOption extends DatabaseBaseModels {
    mutedAll: boolean;
    priority_id: string;
    muted_priorities?: NotificationPriority[];
    user_id: string;
}

export interface Contributors_Users_Tickets extends DatabaseBaseModels {
    userId: string;
    ticketId: string;
    ticket?: Ticket;
    contributor?: User;
}

export interface Muted_Priority extends DatabaseBaseModels {
    notificationpriorityId: string;
    muteoptionId: string;
    priorities?: NotificationPriority[];
    options?: MuteOption[];
}

export interface LexInvoice extends DatabaseBaseModels {
    id: string;
    lexId: string;
    voucherNumber: string;
    resourceUri: string;
    shippingDate: string;
    shippingEndDate: string;
    company_id: string;
    company?: Company;
    lex_res: string;
    netto: number;
    brutto: number;
    documentFileId: string;
    path: string;
}

export interface CheckListTemplate extends DatabaseBaseModels {
    id: string;
    name: string;
    list: CheckListList[];
    company_id: string;
    company?: Company;
    object_id?: string;
    object: Object;
}

export interface CheckList extends DatabaseBaseModels {
    id: string;
    name: string;
    list: CheckListList[];
    items: number;
    items_ok: number;
    items_not_ok: number;
    company_id: string;
    company?: Company;
    object_id?: string;
    object?: Object;
    user_id?: string;
    user?: User;
    worklog_id?: string;
    worklog?: WorkLog;
}

export interface MailTemplate extends DatabaseBaseModels {
    id: string;
    name: string;
    summary: string;
    text: string;
}

export interface MailLog extends DatabaseBaseModels {
    id: string;
    summary: string;
    text: string;
    from: string;
    to: string;
    status: string;
    superadmin_id?: string;
    mailtemplate_id?: string;
    company_id?: string;
    superadmin?: SuperAdmin;
    mailTemplate?: MailTemplate;
    company?: Company;
}

export interface SepaMandat extends DatabaseBaseModels {
    iban: string;
    bic: string;
    bank_name: string;
    name: string;
    address: string;
    country: string;
    countrycode: string;
    reference: string;
    signed: string;
    active: boolean;
    company_id: string;
    company?: Company;
}

export interface RenewIntervals extends DatabaseBaseModels {
    description: string;
    name: string;
    netto: number;
    interval: number;
    unitOfTime: 'day' | 'month' | 'year';
    default: boolean;
    appversion: number;
    sortorder: number;
    upselling_index: number;
    module_timetracking: boolean;
    price_id: string;
    price?: Price;
}

export interface Users_Objects extends DatabaseBaseModels {
    objectId: string;
    userId: string;
}

export interface Share_Worklog_Users extends DatabaseBaseModels {
    worklogId: string;
    AppuserUsersObjectId: string;
    HvId: string;
}


// From here Custom Interfaces

// CheckListList
export interface CheckListList {
    description: string;
    state?: 'ok' | 'not_ok';
    comment?: string;
    childservices_CheckList?: CheckListList[];
}

// Login for App
export interface LoginData {
    key: string;
    customerType: AppUserTypes;
    houseNumber: string;
    api_key?: string;
    uuid?: string;
}

// Login for Admin
export interface LoginCredentials {
    email: string;
    username: string;
    password: string;
    type: SessionType;
}

// Registrierung für App
export interface RegisterCredentials {
    name: string;
    email: string;
    password: string;
    password_confirmed: string;
}

export interface SuperAdminMail {
    summary: string;
    text: string;
    from?: string;
    to: string;
    status: string;
    user_id?: string;
    mailtemplate_id?: string;
    company_id?: string;
    property_management_id?: string;
}

export interface TicketBulUpdate {
    ids: string[];
    field: string;
    value: any;
}

export interface WorklogBulUpdate {
    ids: string[];
    field: string;
    value: any;
}

export interface Order extends DatabaseBaseModels {
    id: string;
    name: string;
    ip: string;
    company_id: string;
    company?: Company;
}

export interface Room {
    id: string;
    name: string;
    description: string;
    visibleAppuser: boolean;
    object_id: string;
    object?: Object;
}

export interface SharedTicket {
    id: string;
    hash: string;
    link: string;
    comment: string;
    files: boolean;
    persons: boolean;
    comments: boolean;
    ticket?: Ticket;
    ticket_id: string;
    user?: User;
    user_id: string;
}

export interface SharedWorkLog {
    id: string;
    hash: string;
    link: string;
    worklog?: WorkLog;
}

export interface TicketChange {
    id: string;
    beforeChange: any;
    afterChange: any;
    ticket_id: string;
    user_id: string;
    user?: User;
    ticket?: Ticket;
}

export interface TimeTrackingType {
    id: string;
    name: string;
    icon_app: string;
    icon_web: string;
    color: string;
    background_color: string;
}

export interface TimeTrackingTimeStamps {
    id: string;
    timestamp: string;
    average: number;
    action: TrackingAction;
    type: string;
    timetracking_type?: TimeTrackingType;
    manipulated: boolean;
    user_id: string;
    user?: User;
    geolocation?: GeoLocation;
    _average: number;
    _synced: boolean;
    _error: timestamp_ValidationError;
    _disabled: boolean;
}

export interface TimeTrackingDays {
    id: string;
    date: string;
    worktime: number;
    breaktime: number;
    absenttime: number;
    needed: number;
    total: number;
    saldo: number;
    timetracking_timestamps: TimeTrackingTimeStamps[];
    absents: Absent[];
    user_id: string;
    manualEntry: boolean;
    prescribedBreakTimeDiff: number;
    holiday?: PublicHoliday;
    _complete: boolean;
    _errorCount: number;
    _disabled: boolean;
}

export interface TimeTrackingMonth {
    id: string;
    year: number;
    month: number;
    start: string;
    worktime: number;
    breaktime: number;
    absenttime: number;
    needed: number;
    total: number;
    saldo: number;
    timetracking_days: TimeTrackingDays[];
    user_id: string;
    locked: boolean;
    ignored: boolean;
    _canLock: boolean;
}

export interface PublicHoliday extends DatabaseBaseModels {
    id: string;
    date: number;
    name: string;
    comment: string;
    all_states: boolean;
    bw: boolean;
    by: boolean;
    be: boolean;
    bb: boolean;
    hb: boolean;
    hh: boolean;
    he: boolean;
    mv: boolean;
    ni: boolean;
    nw: boolean;
    rp: boolean;
    sl: boolean;
    sn: boolean;
    st: boolean;
    sh: boolean;
    th: boolean;
}

export interface Absent extends DatabaseBaseModels {
    id: string;
    start: string;
    end: string;
    daterange: string;
    note: string;
    halfDay: boolean;
    approved: boolean;
    locked: boolean;
    userId: string;
    user: User;
    absentTypeId: string;
    absentType: AbsentType;
}

export interface AbsentType extends DatabaseBaseModels {
    id: string;
    name: string;
    color: string;
    lightcolor: string;
}

export interface timestamp_ValidationError {
    reason: string;
}

export interface ServiceCatalog {
    id: string;
    name: string;
    object_id: string;
    object?: Object;
    service_catalog_items?: ServiceCatalogItem[];
    company?: Company;
    _serviceItemsToSave?: ServiceCatalogItem[];
    _serviceItemIDsToDelete?: string[];
    checklist: boolean;
}

export interface ServiceCatalogItem {
    id: string;
    name: string;
    childservices?: ServiceCatalogItem[];
    service_catalog_id?: string;
    index?: string;
    order?: string;
    _level?: number;
    _checked?: boolean;
    parent_id?: string;
    description?: string;
    state?: 'ok' | 'not_ok';
    comment?: string;
}

export interface ActivityLog {
    id: string;
    activity: string;
    description: string;
    createdAt: string;
    user_id?: string;
}

export interface Price {
    id: string;
    name: string;
    renewintervals: RenewIntervals[];
    default: boolean;
}

export interface completedServices {
    name: string;
    createdAt: string;
}

export interface selectedServices {
    name: string;
    createdAt: string;
}


export interface SaldoObject {
    company: Company;
    mode?: 'full' | 'draft';
}

export interface TimeTrackingWorkWeek {
    monday: number,
    tuesday: number,
    wednesday: number,
    thursday: number,
    friday: number,
    saturday: number,
    sunday: number
}

export interface GroupedWasteplan {
    _originalName: string;
    name: string;
    wastePlans: Wasteplan[];
    _color: string;
    _in: WasteplanGroupAction;
    _out: WasteplanGroupAction;
}

export interface WasteplanGroupAction {
    count: number;
    day: 'same' | 'day' | 'workday';
}

export interface Tag extends DatabaseBaseModels {
    name: string;
    color: string;
    company_id: string;
    entity: (EntityTags)[];
    entity_count?: number;
}

export type availableLanguages = 'de' | 'en';
export type EntityTags = 'ticket' | 'task' | 'worklog' | 'object';
export type ObjectPageSegments = 'meldungen' | 'infos';
export type AbsencePageSegments = 'general' | 'history';
export type HVPageSegments = 'general' | 'contactPerson';
export type checkListList = 'checklists' | 'servicecatalogs';
export type EmployeeObjectPageSegments = 'general' | 'infos' | 'tickets' | 'counter' | 'files' | 'lists';
export type TeamPageSegment = 'myteams' | 'allteams';
export type Salutation = 'Frau' | 'Herr' | 'Firma' | '';
export type EmployeeTicketsSegments = 'generalTickets' | 'myTickets';
export type AppUserTypes = 'mieter' | 'eigentuemer' | 'sonstige';
export type WorkLogPageSegments = 'open' | 'closed';
export type UserTypes = 'customer' | 'employee' | 'hv';
export type CustomerType = 'company' | 'person';
export type StatisticTypes = 'users' | 'objects' | 'tickets' | 'propertymanagements';
export type ApplicationTypes = 'company' | 'property_management';
export type PropertyManagementRequestAction = 'accept' | 'decline';
export type Roles = 'employee' | 'manager' | 'admin';
export type AlertType = 'success' | 'info' | 'warning' | 'error';
export type TrackingAction = 'KOMMEN' | 'PAUSE' | 'GEHEN';
export type PhoneTypes = 'Notfall' | 'Privat Mobil' | 'Arbeit Mobil' | 'Privat Festnetz' | 'Arbeit Festnetz' | 'Fax' | 'Andere';
export type AddressTypes = 'Adresse' | 'Rechnungsadresse' | 'Andere';
export type EmailTypes = 'Privat' | 'Arbeit' | 'Rechnungsmailadresse' | 'Andere';
export type ContactType = string;
export type Types = 'Hausverwaltung' | 'Elektriker';
export type SessionType = 'browser' | 'app';
export type Units = 'Stück' | 'km' | 'm' | 'cm' | 'mm' | 'kg' | 'g' | 'm2' | 'cm2' | 'm3' | 'cm3' | 'Liter' | 'ml' | '%' | 'Stunden' | 'Minuten' ;
export const units: Units[] = ['Stück', 'km' , 'm', 'cm', 'mm', 'kg', 'g', 'm2', 'cm2', 'm3', 'cm3', 'Liter', 'ml', '%', 'Stunden', 'Minuten'];
export type Waste = 'general' | 'in' | 'out';
export type WasteCategory =
    | 'Papier'
    | 'Gelber Sack'
    | 'Biomüll'
    | 'Restmüll'
    | 'Glas'
    | 'Altpapier'
    | 'Elektroschrott'
    | 'Sperrmüll'
    | 'Schadstoffe'
    | 'Altmetall'
    | 'Batterien'
    | 'Kunststoffe'
    | 'Textilien'
    | 'Gartenabfälle'
    | 'Altglas'
    | 'Holz'
    | 'Sonstiges';
export const wasteplanColor = ['#F3EA71', '#70BCE5', '#74E874', '#DC9B6F', '#7E7A7A'];
export const activities = ['CREATE_EMPLOYEE', 'DEACTIVATE_EMPLOYEE', 'ACTIVATE_EMPLOYEE', 'CLOSE_SESSION', '%_EMPLOYEE', '%_RENEW', 'ERROR_%'];
export const companyModules = ['MAPS', 'MAILIMPORT'];
export const consumableGroups: any = {
    CONSUMABLE: 'Verbrauchsgegenstand',
    TOOL: 'Werkzeug',
    VEHICLE: 'Fahrzeug',
    MACHINE: 'Maschine',
    SERVICE: 'Dienstleistung',
}
