import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {Company, LoginCredentials, Notification, RegisterCredentials, Session, User} from '../../../../../database-models';
import {ActivatedRoute, Router} from '@angular/router';


@Injectable()
export class AuthenticationService implements OnInit {
  public currentUserSubject: BehaviorSubject<User>;
  public sessionSubject: BehaviorSubject<string>;
  public currentUser: Observable<User>;
  public session: Observable<string>;

  public logoutSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  sessionValue: string = null;
  private _lexofficeApiActive: boolean = false;
  private _mailImportActive: boolean = false;

  private apiURL = environment.apiURL;



  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.sessionValue = localStorage.getItem('session');
    this.sessionSubject = new BehaviorSubject<string>(this.sessionValue);
    this.currentUser = this.currentUserSubject.asObservable();
    this.session = this.sessionSubject.asObservable();

    this.sessionSubject.subscribe(session => {
      this.sessionValue = session;
    });
  }

  public ngOnInit(): void {


  }

  public loadCurrentUser(): void {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json').set('apikey', this.sessionValue);
    this.http.get(this.apiURL + '/admin/user/me', {headers: headers}).subscribe((user: User) => {
      this.setCurrentUser(user);
    }, onerror => {
      console.log(onerror);
    });

  }


  public setCurrentUser(user: User): void {
    this.currentUserSubject.next(user);
    if (user.id) {
      localStorage.setItem('currentUser', JSON.stringify(user));
      //@ts-ignore
      //window.$chatwoot.reset();
      //@ts-ignore
      window.$chatwoot.setUser(user.id, {
        email: user.email,
        identifier_hash: user.hmac,
        name: user.name,
        avatar_url: user.avatarPath ? environment.apiURL + '/' + user.avatarPath : undefined,
        phone_number: user.phone,
        company_name: user.company?.name,

      });
      //@ts-ignore
      window.$chatwoot.setCustomAttributes({
        appcompanykey: user.company?.code,
        appcompanyid: user.company?.id,
        appnextrenew: user.nextRenew || undefined,
        appadmin: user.isAdmin,
        appmanager: user.isManager,
      });

    }

    this._lexofficeApiActive = !!user.company?.lexoffice?.active;

    this._mailImportActive = false;
    if (user.company?.mailImportConfigs) {
      for (let config of user.company?.mailImportConfigs) {
        if (config.active) {
          this._mailImportActive = true;
        }
      }
    }

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  get lexofficeApiActive(): boolean {
    return this._lexofficeApiActive;
  }


  get mailImportActive(): boolean {
    return this._mailImportActive;
  }


  public login(data: LoginCredentials) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    data.type = 'browser';
    return this.http.post(this.apiURL + '/login/', data, {headers}).pipe(map((session: Session) => {
      if (session && session.user) {
        localStorage.setItem('currentUser', JSON.stringify(session.user));
        localStorage.setItem('session', session.api_key);
        this.currentUserSubject.next(session.user);
        this.sessionSubject.next(session.api_key);
      }
      return session.user;
    }));
  }

  public forgotPassword(data: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.apiURL + '/password/', data, {headers}).pipe(map((user: User) => {
      return user;
    }));
  }

  public resetPassword(data: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.apiURL + '/reset/', data, {headers}).pipe(map((user: User) => {
      return user;
    }));
  }

  public checkExpiryToken(hash: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.get(this.apiURL + '/reset/' + hash, {headers});
  }

  public async logout(sessionExpired = false): Promise<void> {
    if (this.sessionValue) {
      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json');
      this.http.delete(this.apiURL + '/logout/' + this.sessionValue, {headers}).subscribe(res => {
      });
    }

    this.logoutSubject.next(true);

    localStorage.removeItem('session');
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.sessionSubject.next(null);
    if (sessionExpired) {
      const routeToReturn = this.route.snapshot.url[0]?.path;
      this.router.navigate(['login'], {queryParams: {expired: true, returnUrl: routeToReturn}});
    } else {
      this.router.navigate(['login']);
    }


  }

  public register(data: RegisterCredentials) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.apiURL + '/register', data, {headers}).pipe(map((user: User) => {
      return user;
    }));
  }
}
