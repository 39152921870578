import {NgModule} from '@angular/core';
import {AsyncPipe, CommonModule, DatePipe, NgIf} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ThemeConstantService} from './services/theme-constant.service';
import {SearchPipe} from './pipes/search.pipe';
import {MasterTableComponent} from './components/master-table/master-table.component';
import {EditUserComponent} from './components/edit-user/edit-user.component';
import {ContactComponent} from './components/contact/contact.component';
import {FileListComponent} from './components/file-list/file-list.component';
import {NoteEditorComponent} from './components/free-text-notes/note-editor/note-editor.component';
import {QuillModule} from 'ngx-quill';
import {EditContactComponent} from './components/edit-contact/edit-contact.component';
import {EditObjectMetaComponent} from './components/object/edit-object-meta/edit-object-meta.component';
import {EditNoteComponent} from './components/object/edit-note/edit-note.component';
import {WorklogListComponent} from './components/worklog-list/worklog-list.component';
import {EditWorklogComponent} from './components/edit-worklog/edit-worklog.component';
import {TestPeriodBannerComponent} from './components/test-period-banner/test-period-banner.component';
import {EditTaskComponent} from './components/task/edit-task/edit-task.component';
import {RecurringEditorComponent} from './components/task/recurring-editor/recurring-editor.component';
import {
  MbscCalendarModule,
  MbscCheckboxModule, MbscDatepickerModule, MbscEventcalendarModule,
  MbscInputModule,
  MbscRadioModule,
  MbscSegmentedModule,
  MbscSelectModule
} from '@mobiscroll/angular';
import {CheckListTemplatesListComponent} from './components/checklist/check-list-templates-list/check-list-templates-list.component';
import {EditChecklistTemplateComponent} from './components/checklist/edit-checklist-template/edit-checklist-template.component';
import {CheckListListComponent} from './components/checklist/check-list-list/check-list-list.component';
import {ChecklistDetailsComponent} from './components/checklist/checklist-details/checklist-details.component';
import {WorklogCardComponent} from './components/worklog-card/worklog-card.component';
import {ConsumableListComponent} from './components/consumables/consumable-list/consumable-list.component';
import {SendMailEditorComponent} from './components/send-mail-editor/send-mail-editor.component';
import {AppuserEditComponent} from './components/appuser-edit/appuser-edit.component';
import {HvObjectConnectionComponent} from './components/object/hv-object-connection/hv-object-connection.component';
import {TicketIssuerComponent} from './components/ticket/ticket-issuer/ticket-issuer.component';
import {TransactionsListComponent} from './components/transactions-list/transactions-list.component';
import {EditUserBillingIntervalComponent} from './components/edit-user-billing-interval/edit-user-billing-interval.component';
import {SelectUserBillingIntervalComponent} from './components/select-user-billing-interval/select-user-billing-interval.component';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzDescriptionsModule} from 'ng-zorro-antd/descriptions';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzAutocompleteModule} from 'ng-zorro-antd/auto-complete';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzCommentModule} from 'ng-zorro-antd/comment';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {FirstStepsComponent} from './components/first-steps/first-steps.component';
import {TicketsYearChartComponent} from './charts/tickets-year-chart/tickets-year-chart.component';
import {NgChartjsModule} from 'ng-chartjs';
import {NzAnchorModule} from 'ng-zorro-antd/anchor';
import {ConsumablesByObjectComponent} from './components/consumables/consumables-by-object/consumables-by-object.component';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {WorklogEditChecklistComponent} from './components/checklist/worklog-edit-checklist/worklog-edit-checklist.component';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {SignatureWorklogComponent} from './components/signature-worklog/signature-worklog.component';
import {ObjectCustomerSelectComponent} from './components/object-customer-select/object-customer-select.component';
import {NzListModule} from 'ng-zorro-antd/list';
import {TaskObjectSelectComponent} from './components/task-object-select/task-object-select.component';
import {IbanValidatorDirective} from './directives/iban-validator.directive';
import {CustomerSubscriptionComponent} from './components/customer-subscription/customer-subscription.component';
import {TicketShareComponent} from './components/ticket/ticket-share/ticket-share.component';
import {PreviewSharedTicketComponent} from './components/ticket/preview-shared-ticket/preview-shared-ticket.component';
import {ActiveUsersWithPricesComponent} from './components/active-users-with-prices/active-users-with-prices.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {TicketShareListComponent} from './components/ticket/ticket-share-list/ticket-share-list.component';
import {IsManagerDirective} from './directives/is-manager.directive';
import {TimestampListComponent} from './components/timetracking/timestamp-list/timestamp-list.component';
import {EditTimestampsComponent} from './components/timetracking/edit-timestamps/edit-timestamps.component';
import {ObjectChartComponent} from './charts/object-chart/object-chart.component';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzPopconfirmModule} from 'ng-zorro-antd/popconfirm';
import {PrintWorklogComponent} from './template/print/print-worklog/print-worklog.component';
import {ServicecatalogChecklistComponent} from './components/servicecatalog-checklist/servicecatalog-checklist.component';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {UserObjectPermissionComponent} from './components/user-object-permission/user-object-permission.component';
import {UserSelectComponent} from './components/user-select/user-select.component';
import {EditContactV2Component} from './components/edit-contact-v2/edit-contact-v2.component';
import {EditContactPersonComponent} from './components/edit-contact-person/edit-contact-person.component';
import {ContactCardComponent} from './components/contact-card/contact-card.component';
import {ObjectResidentsComponent} from './components/object-residents/object-residents.component';
import {
  WorklogShareListComponent
} from './template/print/print-worklog/worklog-share-list/worklog-share-list.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ResidentDetailsComponent} from './components/object-residents/resident-details/resident-details.component';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {ResidentSelectComponent} from './components/object-residents/resident-select/resident-select.component';
import {CounterComponent} from './components/counter/counter.component';
import {EditCounterComponent} from './components/counter/edit-counter/edit-counter.component';
import {CounterStatusHistorieComponent} from './components/counter/counter-status-historie/counter-status-historie.component';
import {AbsentComponent} from './components/absent/absent/absent.component';
import {QRCodeModule} from 'angularx-qrcode';
import {SessionListComponent} from './components/session-list/session-list.component';
import {SupportDrawerComponent} from './components/support-drawer/support-drawer.component';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {UserCardComponent} from './components/user-card/user-card.component';
import {UsernameAvatarComponent} from './components/username-avatar/username-avatar.component';
import {TimeTrackingWorkWeekComponent} from './components/timetracking/time-tracking-work-week/time-tracking-work-week.component';
import {NzInputNumberModule} from 'ng-zorro-antd/input-number';
import {CounterStatusHistorieListComponent} from './components/counter/counter-status-historie-list/counter-status-historie-list.component';
import {NzStatisticModule} from 'ng-zorro-antd/statistic';
import {TimetrackingLockMonthComponent} from './components/timetracking/timetracking-lock-month/timetracking-lock-month.component';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {ManualTimesComponent} from './components/timetracking/timestamp-list/manual-times/manual-times.component';
import {TicketListComponent} from './components/ticket/ticket-list/ticket-list.component';
import {NewWorklogComponent} from './components/new-worklog/new-worklog.component';
import {GpsListComponent} from './components/gps-list/gps-list.component';
import {MinAppversionDirective} from './directives/min-appversion.directive';
import {ContactSelectListComponent} from './components/contact-select-list/contact-select-list.component';
import {EditFileComponent} from './components/edit-file/edit-file.component';
import {WasteplanListComponent} from './components/wasteplan/wasteplan-list/wasteplan-list.component';
import {WasteplanImportComponent} from './components/wasteplan/wasteplan-import/wasteplan-import.component';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {EditWasteplanComponent} from './components/wasteplan/edit-wasteplan/edit-wasteplan.component';
import {TimeTrackingComponent} from './components/timetracking/time-tracking/time-tracking.component';
import {ObjectNoteComponent} from './components/object-note/object-note.component';
import {WasteplanCalendarComponent} from './components/wasteplan/wasteplan-calendar/wasteplan-calendar.component';
import {GlobalSearchV2Component} from './components/global-search/global-search-v2/global-search-v2.component';
import {GlobalSearchComponent} from './components/global-search/global-search/global-search.component';
import {CustomerSelectListComponent} from './components/customer-select-list/customer-select-list.component';
import {CustomerObjectComponent} from './components/customer/customer-object/customer-object.component';
import {EditCustomerComponent} from './components/customer/edit-customer/edit-customer.component';
import {SafePipeModule} from 'safe-pipe';
import {UsersComponent} from './components/users/users.component';
import {ObjectWorklogListComponent} from './components/object-worklog-list/object-worklog-list.component';
import {ContributerSelectListComponent} from './components/contributer/contributer-select-list/contributer-select-list.component';
import {WasteplanSchedulerListComponent} from './components/wasteplan/wasteplan-scheduler-list/wasteplan-scheduler-list.component';
import {NzSliderModule} from 'ng-zorro-antd/slider';
import {TimestampListMonthsComponent} from './components/timetracking/timestamp-list-months/timestamp-list-months.component';
import {LexofficeAuthComponent} from './components/lexoffice/lexoffice-auth/lexoffice-auth.component';
import {LexofficeContactListComponent} from './components/lexoffice/lexoffice-contact-list/lexoffice-contact-list.component';
import {LexofficeTagComponent} from './components/lexoffice/lexoffice-tag/lexoffice-tag.component';
import {FileBrowserComponent} from './components/file-browser/file-browser.component';
import {TicketTagComponent} from './components/ticket/ticket-tag/ticket-tag.component';
import {TicketOverviewComponent} from './components/ticket/ticket-overview/ticket-overview.component';
import {TaskTagComponent} from './components/task/task-tag/task-tag.component';
import {TaskOverviewComponent} from './components/task/task-overview/task-overview.component';
import {TimeTrackingUserListComponent} from './components/timetracking/time-tracking-user-list/time-tracking-user-list.component';
import {
  LexofficeWorklogToInvoiceComponent
} from './components/lexoffice/lexoffice-worklog-to-invoice/lexoffice-worklog-to-invoice.component';
import {CreateObjectsComponent} from './components/object/create-objects/create-objects.component';
import {LexofficeIconComponent} from './components/lexoffice/lexoffice-icon/lexoffice-icon.component';
import {LexofficeArticlesListComponent} from './components/lexoffice/lexoffice-articles-list/lexoffice-articles-list.component';
import {Nl2BrPipe} from './pipes/nl2br.pipe';
import {LightboxComponent} from './components/lightbox/lightbox.component';
import {LightboxDirective} from './directives/lightbox.directive';
import {MapComponent} from './components/maps/map/map.component';
import {MapSetCompanyLocationComponent} from './components/maps/map-set-company-location/map-set-company-location.component';
import {MapMarker} from './components/maps/map-marker';
import {MailImportConfigComponent} from './components/mail-import/mail-import-config/mail-import-config.component';
import {EntityTagSelectorComponent} from './components/entity-tag-selector/entity-tag-selector.component';
import {MailImportComponent} from './components/mail-import/mail-import/mail-import.component';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    SearchPipe,
    Nl2BrPipe,
    MasterTableComponent,
    EditUserComponent,
    FileListComponent,
    EditObjectMetaComponent,
    EditNoteComponent,
    WorklogListComponent,
    EditWorklogComponent,
    TestPeriodBannerComponent,
    EditTaskComponent,
    CheckListTemplatesListComponent,
    CheckListListComponent,
    TicketIssuerComponent,
    FirstStepsComponent,
    TicketsYearChartComponent,
    ObjectCustomerSelectComponent,
    IbanValidatorDirective,
    CustomerSubscriptionComponent,
    ActiveUsersWithPricesComponent,
    TicketShareListComponent,
    IsManagerDirective,
    ObjectChartComponent,
    UserSelectComponent,
    ContactCardComponent,
    ObjectResidentsComponent,
    ResidentSelectComponent,
    CounterComponent,
    SessionListComponent,
    SupportDrawerComponent,
    UsernameAvatarComponent,
    TicketListComponent,
    TicketListComponent,
    MinAppversionDirective,
    ContactSelectListComponent,
    WasteplanListComponent,
    TimeTrackingComponent,
    ObjectNoteComponent,
    WasteplanCalendarComponent,
    GlobalSearchV2Component,
    GlobalSearchComponent,
    CustomerSelectListComponent,
    EditCustomerComponent,
    UsersComponent,
    ObjectWorklogListComponent,
    ContributerSelectListComponent,
    TransactionsListComponent,
    WasteplanSchedulerListComponent,
    LexofficeAuthComponent,
    LexofficeContactListComponent,
    LexofficeTagComponent,
    WasteplanSchedulerListComponent,
    TicketOverviewComponent,
    TaskTagComponent,
    LexofficeIconComponent,
    LightboxComponent,
    LightboxDirective,
    MapComponent,
    MapSetCompanyLocationComponent,
    EntityTagSelectorComponent,
    MailImportConfigComponent,
    MailImportComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    QuillModule,
    MbscSegmentedModule,
    MbscInputModule,
    MbscSelectModule,
    MbscCheckboxModule,
    MbscRadioModule,
    MbscDatepickerModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzRadioModule,
    NzToolTipModule,
    NzButtonModule,
    NzDescriptionsModule,
    NzCardModule,
    NzBadgeModule,
    NzIconModule,
    NzCollapseModule,
    NzUploadModule,
    NzSpinModule,
    NzAutocompleteModule,
    NzDatePickerModule,
    NzAlertModule,
    NzTableModule,
    NzCommentModule,
    NzAvatarModule,
    NzDropDownModule,
    NzDividerModule,
    NzTabsModule,
    NzCheckboxModule,
    NzTagModule,
    NgChartjsModule,
    NzAnchorModule,
    NzTypographyModule,
    NzLayoutModule,
    NzListModule,
    ClipboardModule,
    NzPopoverModule,
    NzPopconfirmModule,
    CommonModule,
    CommonModule,
    CommonModule,
    CommonModule,
    NzSwitchModule,
    ColorPickerModule,
    DragDropModule,
    NzModalModule,
    QRCodeModule,
    NzDrawerModule,
    NzInputNumberModule,
    MbscCalendarModule,
    NzStatisticModule,
    NzProgressModule,
    DatePipe,
    NzStepsModule,
    DatePipe,
    AsyncPipe,
    MbscEventcalendarModule,
    SafePipeModule,
    NzSliderModule
  ],
  declarations: [
    SearchPipe,
    MasterTableComponent,
    EditUserComponent,
    ContactComponent,
    FileListComponent,
    NoteEditorComponent,
    EditContactComponent,
    EditObjectMetaComponent,
    EditNoteComponent,
    WorklogListComponent,
    WorklogListComponent,
    EditWorklogComponent,
    TestPeriodBannerComponent,
    EditTaskComponent,
    RecurringEditorComponent,
    CheckListTemplatesListComponent,
    EditChecklistTemplateComponent,
    CheckListListComponent,
    ChecklistDetailsComponent,
    WorklogCardComponent,
    ConsumableListComponent,
    SendMailEditorComponent,
    AppuserEditComponent,
    HvObjectConnectionComponent,
    TicketIssuerComponent,
    TransactionsListComponent,
    EditUserBillingIntervalComponent,
    SelectUserBillingIntervalComponent,
    FirstStepsComponent,
    TicketsYearChartComponent,
    ConsumablesByObjectComponent,
    WorklogEditChecklistComponent,
    SignatureWorklogComponent,
    ObjectCustomerSelectComponent,
    TaskObjectSelectComponent,
    IbanValidatorDirective,
    CustomerSubscriptionComponent,
    TicketShareComponent,
    PreviewSharedTicketComponent,
    CustomerSubscriptionComponent,
    ActiveUsersWithPricesComponent,
    TicketShareListComponent,
    IsManagerDirective,
    TimestampListComponent,
    EditTimestampsComponent,
    ObjectChartComponent,
    PrintWorklogComponent,
    ServicecatalogChecklistComponent,
    UserObjectPermissionComponent,
    UserSelectComponent,
    EditContactV2Component,
    EditContactPersonComponent,
    ContactCardComponent,
    WorklogShareListComponent,
    ObjectResidentsComponent,
    ResidentDetailsComponent,
    ResidentSelectComponent,
    CounterComponent,
    EditCounterComponent,
    CounterStatusHistorieComponent,
    AbsentComponent,
    SessionListComponent,
    SupportDrawerComponent,
    UserCardComponent,
    UsernameAvatarComponent,
    TimeTrackingWorkWeekComponent,
    UsernameAvatarComponent,
    CounterStatusHistorieListComponent,
    TimetrackingLockMonthComponent,
    ManualTimesComponent,
    NewWorklogComponent,
    GpsListComponent,
    ManualTimesComponent,
    TicketListComponent,
    MinAppversionDirective,
    ContactSelectListComponent,
    EditFileComponent,
    WasteplanListComponent,
    WasteplanImportComponent,
    EditWasteplanComponent,
    TimeTrackingComponent,
    ObjectNoteComponent,
    WasteplanCalendarComponent,
    GlobalSearchV2Component,
    GlobalSearchComponent,
    CustomerSelectListComponent,
    CustomerObjectComponent,
    EditCustomerComponent,
    UsersComponent,
    ObjectWorklogListComponent,
    ContributerSelectListComponent,
    WasteplanSchedulerListComponent,
    TimestampListMonthsComponent,
    LexofficeAuthComponent,
    LexofficeContactListComponent,
    LexofficeTagComponent,
    TimestampListMonthsComponent,
    FileBrowserComponent,
    TicketTagComponent,
    TicketOverviewComponent,
    TaskTagComponent,
    TaskOverviewComponent,
    TimeTrackingUserListComponent,
    LexofficeWorklogToInvoiceComponent,
    TimeTrackingUserListComponent,
    CreateObjectsComponent,
    TimeTrackingUserListComponent,
    LexofficeIconComponent,
    LexofficeArticlesListComponent,
    Nl2BrPipe,
    LightboxComponent,
    LightboxDirective,
    MapComponent,
    MapSetCompanyLocationComponent,
    MailImportConfigComponent,
    EntityTagSelectorComponent,
    MailImportComponent
  ],
  providers: [
    ThemeConstantService
  ]
})

export class SharedModule {
}
