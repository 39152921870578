import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Counter, Object, Task} from '../../../../../../database-models';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ActivatedRoute, Router} from '@angular/router';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {EditCounterComponent, ModalData as EditCounterModalData} from './edit-counter/edit-counter.component';
import {
  CounterStatusHistorieComponent,
  ModalData as CounterStatusHistorieModalData
} from './counter-status-historie/counter-status-historie.component';
import {
  CounterStatusHistorieListComponent, ModalData as CounterStatusHistorieListModalData
} from './counter-status-historie-list/counter-status-historie-list.component';
import {ExportService} from '../../services/export.service';
import {DatePipe} from '@angular/common';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {environment} from '../../../../environments/environment';
import {image} from 'html2canvas/dist/types/css/types/image';
import moment from 'moment/moment';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {HttpParams} from '@angular/common/http';
import {groupBy} from '../../../../../../helper-functions';
import {TaskService} from '../../services/task.service';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css']
})
export class CounterComponent implements OnInit {

  public backendURL = environment.apiURL;
  public loading = false;

  public counters: Counter[];

  public checked = false;
  public indeterminate = false;
  public listOfCurrentPageData: Counter[] = [];
  public setOfCheckedId = new Set<string>();

  public modal: NzModalRef;

  public filter = {
    show: true,
    objects: [],
    search: null,
  };

  public tableState: any = {
    total: 0,
    pageIndex: 1,
    pageSize: 20,
  };

  public sortOrders = {
    counterNumber: null,
    counterType: null,
    note: null,
    readingDate: null
  };

  constructor(
    public api: ApiService,
    private message: NzMessageService,
    public router: Router,
    public route: ActivatedRoute,
    public exportService: ExportService,
    private datePipe: DatePipe,
    private modalService: NzModalService,
    private taskService: TaskService
  ) {
    this.route.queryParams.subscribe(value => {
      if (value.objectId) {
        this.api.getObjects(value.objectId).subscribe(obj => {
          this.filter.objects = [obj];
          this.filter.show = true;
          this.getCounters();
        });
      } else {
        this.getCounters();
      }
    });
  }

  ngOnInit(): void {


    const storedOrderFn = localStorage.getItem('tableHeaderCounter');
    if (storedOrderFn) {
      this.sortOrders = JSON.parse(storedOrderFn);
    }
  }

  public getCounters(queryParams?: NzTableQueryParams, resetSkip: boolean = false): void {
    this.loading = true;
    this.setOfCheckedId.clear();
    let httpParams = new HttpParams();
    if (this.filter.search) {
      httpParams = httpParams.append('search', this.filter.search);
    }
    if (this.filter.objects) {
      httpParams = httpParams.append('objectIds', JSON.stringify(this.filter.objects.map(obj => obj.id)));
    }

    if (resetSkip) {
      this.tableState.pageIndex = 1;
    }

    httpParams = httpParams.append('pageIndex', this.tableState.pageIndex - 1);


    httpParams = httpParams.append('pageSize', this.tableState.pageSize);

    if (queryParams?.sort) {
      this.tableState.sort = queryParams.sort;
    }

    if (this.tableState.sort) {
      httpParams = httpParams.append('sort', JSON.stringify(this.tableState.sort));
    }

    this.api.getCounters(httpParams).subscribe((counters: any) => {
      this.tableState.total = counters.count;
      this.counters = counters.rows;
      this.loading = false;
    }, error => {
      this.message.error('Zähler konnten nicht geladen werden!');
      this.loading = false;
    });
  }

  timeout = null;

  public triggerSearch(skipTimeout: boolean = false): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.getCounters(null, true);
    }, skipTimeout ? 0 : 1000);
  }

  public editCounter(counter?: Counter): void {
    const editModal = this.modalService.create<EditCounterComponent, EditCounterModalData>({
      nzContent: EditCounterComponent,
      nzMaskClosable: false,
      nzData: {
        counter: counter
      },
      nzFooter: null,
      nzWidth: 700,
    });

    editModal.afterClose.subscribe((data?: any) => {
      console.log(data);
      if (data?.data) {
        this.counters.push(data?.data);
        this.getCounters();
        this.editCounterStatus(data.data);
      } else {
        this.getCounters();
      }
    });
  }

  public editCounterStatus(counter: Counter): void {
    this.modal = this.modalService.create<CounterStatusHistorieComponent, CounterStatusHistorieModalData>({
      nzContent: CounterStatusHistorieComponent,
      nzData: {
        counterId: counter.id,
        counter: counter
      },
      nzFooter: null,
      nzWidth: 700,
    });
    this.modal.afterClose.subscribe((data: any) => {
      this.getCounters();
    });
  }

  public showCounterStatusHistorieList(counter: Counter): void {
    this.modal = this.modalService.create<CounterStatusHistorieListComponent, CounterStatusHistorieListModalData>({
      nzContent: CounterStatusHistorieListComponent,
      nzData: {
        counter: counter
      },
      nzFooter: null,
      nzWidth: '90%',
    });
    this.modal.afterClose.subscribe((data: any) => {
      this.getCounters();
    });
  }

  public deleteCounter(id: string, index: number): void {
    this.modalService.confirm({
      nzTitle: '<i>Zähler löschen</i>',
      nzContent: 'Sind Sie sicher, dass Sie diesen Zähler unwiderruflich löschen möchten?',
      nzOnOk: () => {
        this.api.deleteCounter(id).subscribe(res => {
          this.message.success('Zähler wurde gelöscht');
          this.getCounters();
        }, error => {
          this.message.error('Zähler konnte nicht gelöscht werden');
        });
      }
    });
  }

  private async getImageBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => reject(error);
      img.src = url;
    });
  }

  public async exportReport(counter: Counter, splice: number, withImg: boolean): Promise<void> {
    let exportJSON: any[] = [];
    let doc = new jsPDF();
    for (const status of counter.counterStatuses) {
      if (status.files[0] && status.files[0].url && withImg) {
        status.files[0]._base64image = await this.getImageBase64(this.backendURL + '/' + status?.files[0]?.url);
      }

      const row = {
        'Datum': this.datePipe.transform(status.readingDate, 'dd.MM.y'),
        'Zählerstand': status.count + ' (' + counter.counterType.unit + ')',
        Notiz: status.note,
        ...(withImg && {'Bild': ''})
      };
      exportJSON.push(row);
    }

    if (splice) {
      exportJSON = exportJSON.splice(0, splice);
    }


    doc.text(counter.counterType.name + ': ' + counter.counterNumber, 15, 10);
    doc.setFontSize(12);
    doc.text(counter.note || '-', 15, 18);


    autoTable(doc, {
      head: [Object.keys(exportJSON[0])],
      body: exportJSON.map(obj => Object.values(obj)),
      margin: {top: 35},
      headStyles: {
        fillColor: [255, 143, 0]
      },
      bodyStyles: {
        minCellHeight: withImg ? 60 : 10
      },
      columnStyles: {
        0: {cellWidth: withImg ? 25 : 'auto'},
        1: {cellWidth: withImg ? 35 : 'auto'},
        2: {cellWidth: withImg ? 50 : 'auto'},
        3: {cellWidth: withImg ? 70 : 'auto'},
      },
      didDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 3) {
          const base64image = counter.counterStatuses[data.row.index]?.files[0]?._base64image;
          if (base64image) {
            doc.addImage(base64image, 'JPEG', data.cell.x + 6, data.cell.y + 2, 55, 55);
          } else {
            doc.text('', data.cell.x + 2, data.cell.y + 5);
          }
        }
      },
    });

    doc.save(counter.counterNumber + '.pdf');
  }

  private getSelected(): Counter[] {
    const selectedCounters = [];
    for (let id of this.setOfCheckedId) {
      let item = this.counters.find(counter => counter.id === id);
      selectedCounters.push(item);
    }
    return selectedCounters;
  }

  public async exportOverviewReport(withImg: boolean): Promise<void> {


    const selectedCounters = this.getSelected();


    let selectedCountersGrouped = selectedCounters.reduce(function (rv, x) {
      (rv[x['object']['id']] = rv[x['object']['id']] || []).push(x);
      return rv;
    }, {});
    console.log(selectedCounters, selectedCountersGrouped);


    for (const counterGroup in selectedCountersGrouped) {
      let exportJSON: any[] = [];
      for (const counter of selectedCountersGrouped[counterGroup]) {

        if (counter.counterStatuses[0]?.files[0] && counter.counterStatuses[0]?.files[0]?.url && withImg) {
          counter.counterStatuses[0].files[0]._base64image = await this.getImageBase64(this.backendURL + '/' + counter.counterStatuses[0].files[0]?.url);
        }

        const row = {
          'Zählernummer': counter.counterNumber,
          'Typ': counter.counterType.name,
          'Datum': this.datePipe.transform(counter.counterStatuses[0]?.readingDate, 'dd.MM.y'),
          'Zählerstand': (counter.counterStatuses[0]?.count || '-') + ' (' + counter.counterType.unit + ')',
          Notiz: counter.counterStatuses[0]?.note,
          ...(withImg && {'Bild': ''})
        };
        exportJSON.push(row);
      }

      let doc = new jsPDF();

      doc.text('Zählerübersicht', withImg ? 5 : 10, 10);
      doc.setFontSize(12);
      doc.text(selectedCountersGrouped[counterGroup][0].object?.name, withImg ? 5 : 10, 18);

      autoTable(doc, {
        head: [
          [
            {
              content: 'Zählerinformationen',
              colSpan: 2,
              styles: {
                halign: 'center',
                fillColor: [173, 173, 173]
              }
            },
            {
              content: 'Letzte Ablesung',
              colSpan: 4,
              styles: {
                halign: 'center',
                fillColor: [150, 150, 150]
              }
            }
          ],
          Object.keys(exportJSON[0])
        ],
        body: exportJSON.map(obj => Object.values(obj)),
        margin: {top: 25, left: withImg ? 2 : 10},
        headStyles: {
          fillColor: [255, 143, 0]
        },
        bodyStyles: {
          minCellHeight: withImg ? 60 : 10
        },
        columnStyles: {
          0: {cellWidth: withImg ? 30 : 'auto'},
          1: {cellWidth: withImg ? 30 : 'auto'},
          2: {cellWidth: withImg ? 25 : 'auto'},
          3: {cellWidth: withImg ? 30 : 'auto'},
          4: {cellWidth: withImg ? 35 : 'auto'},
          5: {cellWidth: withImg ? 55 : 'auto'}
        },
        didDrawCell: (data) => {
          if (data.section === 'body' && data.column.index === 5) {
            const base64image = selectedCounters[data.row.index]?.counterStatuses[0]?.files[0]?._base64image;
            if (base64image && withImg) {
              doc.addImage(base64image, 'JPEG', data.cell.x + 2, data.cell.y + 5, 50, 50);
            } else {
              doc.text('', data.cell.x + 2, data.cell.y + 5);
            }
          }
        }
      });

      doc.save('Zähler-' + selectedCountersGrouped[counterGroup][0].object?.key + '.pdf');
    }
  }

  public onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData.forEach(({id}) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  public updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  public refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(({id}) => this.setOfCheckedId.has(id));
    this.indeterminate = this.listOfCurrentPageData.some(({id}) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  public onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  public onCurrentPageDataChange(listOfCurrentPageData: Counter[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  public toogleFilter(): void {
    this.filter.show = !this.filter.show;
    this.getCounters();
  }

  public resetFilter(): void {
    this.filter = {
      show: true,
      search: null,
      objects: [],
    };
    localStorage.setItem('worklogListFilter', JSON.stringify(this.filter));
    this.router.navigate(['/counter/']); /*Pfad zu Reseten wenn man von Object Details kommt*/
    this.getCounters();
    this.message.success('Filter wurde zurückgesetzt');
  }

  public checkForActiveFilters(): number {
    let active = 0;
    for (let key in this.filter) {
      if (key !== 'show') {
        if (typeof this.filter[key] === 'boolean') {

          if (this.filter[key] === true || this.filter[key] === false) {
            active++;
          }

        } else if (Array.isArray(this.filter[key])) {
          if (this.filter[key].length > 0) {
            active++;
          }
        } else {
          if (this.filter[key]) {
            active++;
          }
        }
      }
    }
    return active;
  }

  public getClassNextReadingDate(counter: Counter): string {

      const inDays = moment().diff(counter.nextReadingDate, 'days') * -1;

      if (inDays <= 0 || counter.markToDo) {
        return 'background-red color-red text-bold';
      } else if (inDays < 30) {
        return 'background-yellow text-bold';
      }

  }

  public newTask(counter?: Counter): void {
    let start = moment().toISOString();
    let end = moment().add(30, 'minutes').toISOString();

    let task: Task = {
      start,
      end
    } as Task;

    if (counter) {
      if (counter.nextReadingDate && moment().isBefore(counter.nextReadingDate)) {
        task.start = moment(counter.nextReadingDate + ' 12:00').toISOString();
        task.end = moment(counter.nextReadingDate + ' 12:30').toISOString();
        task.title = 'Zählerstand ablesen (' + counter.counterNumber + ')';
        task.description = 'Zählernummer: ' + counter.counterNumber;
        task.object = counter.object;
        task.object_id = counter.object?.id;
        task.counters = [counter];
      }
    } else {
      task.counters = this.getSelected();
      if (task.counters?.length > 0) {
        task.title =  task.counters.length + ' Zähler ablesen';
        task.object = task.counters[0].object;
        task.object_id = task.counters[0].objectId;

        for (let counter of task.counters) {
          if (counter.objectId !== task.object_id) {
            this.modalService.warning({
              nzZIndex: 9999,
              nzTitle: 'Achtung',
              nzContent: 'Sie haben Zähler aus unterschiedlichen Objekten ausgewählt! Eine Zuordnung auf ein Objekt ist daher für die Aufgabe nicht möglich.'
            });
            task.object = null;
            task.object_id = null;
            break;
          }
        }
      }

    }


    this.taskService.newTaskModal(task);
  }

  public setCounter(counter: Counter): void {
    this.api.setCounter(counter).subscribe(value => {
      this.message.success('Zähler wurde gespeichert');
    }, onerror => {

      this.message.warning('Fehler!');

    });
  }

  protected readonly moment = moment;
}
